







































































































































































































































































import { Component, Prop, Vue, Ref } from "vue-property-decorator";

export default {
  data() {
    return {
      tem: require("@/assets/product/img/num.png"),
      time: require("@/assets/product/img/time.png"),
      toggle: 1,
    };
  },
};
